<template>
    <UIRipple class="x-menu-icon" color="black" :opacity="0.15" :speed="3" :class="{ circle: circle }">
        <UIIcon :name="iconName" />
    </UIRipple>
</template>

<script>
import { UIRipple, UIIcon } from '@dundle/ui/components';

export default {
    components: {
        UIRipple,
        UIIcon,
    },

    props: {
        name: {
            type: String,
            default: 'default',
        },

        circle: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        iconName() {
            if (this.name === 'default') {
                return ['fal', 'bars'];
            } else if (this.name == 'arrow') {
                return ['fal', 'arrow-left'];
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.x-menu-icon {
    line-height: 4rem;
    width: 4rem;
    text-align: center;
    font-size: 1.5rem;
    color: $color-header-text-bold;

    &.circle {
        line-height: 3.5rem;
        height: 3.5rem;
        width: 3.5rem;
        margin: 0.25rem;
        border-radius: 2rem;
        overflow: hidden;
    }
}

.x-menu-icon button {
    transform: scale(0.625);
}

.x-menu-icon button:focus {
    outline: none;
}

.x-menu-icon .hamburger-box {
    vertical-align: middle;
}
</style>
