<template>
    <div class="spinner loader">{{ translate('spinner.loading') }}...</div>
</template>

<script>
import TranslationMixin from '~/mixins/TranslationMixin';

export default {
    mixins: [TranslationMixin],
};
</script>

<style lang="scss" scoped>
.loader,
.loader:before,
.loader:after {
    border-radius: 50%;
}
.loader {
    color: $color-primary;
    font-size: 11px;
    text-indent: -99999em;
    margin: 55px auto;
    position: relative;
    width: 5em;
    height: 5em;
    box-shadow: inset 0 0 0 1em;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
}
.loader:before,
.loader:after {
    position: absolute;
    content: '';
}

.loader:before {
    width: 2.6em;
    height: 5.1em;
    background: white;
    border-radius: 5.1em 0 0 5.1em;
    top: -0.05em;
    left: -0.05em;
    transform-origin: 2.6em 2.55em;
    animation: load2 1s infinite ease 0.75s;
}
.loader:after {
    width: 2.6em;
    height: 5.1em;
    background: white;
    border-radius: 0 5.1em 5.1em 0;
    top: -0.05em;
    left: 2.55em;
    transform-origin: 0px 2.55em;
    animation: load2 1s infinite ease;
}

@-webkit-keyframes load2 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@keyframes load2 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
</style>
<i18n>
{
	"de": {
		"spinner": {
			"loading": "Laden"
		}
	},
	"en": {
		"spinner": {
			"loading": "Loading"
		}
	},
	"fr": {
		"spinner": {
			"loading": "Chargement"
		}
	},
	"it": {
		"spinner": {
			"loading": "Un momento prego"
		}
	},
	"nl": {
		"spinner": {
			"loading": "Laden"
		}
	},
	"pt": {
		"spinner": {
			"loading": "Carregando"
		}
    },
	"es": {
		"spinner": {
			"loading": "Cargando"
		}
    },
	"pl": {
		"spinner": {
			"loading": "Ładowanie"
		}
    },
	"tr": {
		"spinner": {
			"loading": "Yükleniyor"
		}
    },
	"ar": {
		"spinner": {
			"loading": "جاري التحميل"
		}
    },
	"da": {
		"spinner": {
			"loading": "Henter"
		}
    },
	"fi": {
		"spinner": {
			"loading": "Ladataan"
		}
	},
	"ru": {
		"spinner": {
			"loading": "Загрузка результатов"
		}
	},
	"sv": {
		"spinner": {
			"loading": "Laddar"
		}
	},
	"nb": {
		"spinner": {
			"loading": "Lader"
		}
	},
	"cs": {
		"spinner": {
			"loading": "Načítání"
		}
	},
	"sk": {
		"spinner": {
			"loading": "Načítanie"
		}
	},
	"hu": {
		"spinner": {
			"loading": "Folyamatban"
		}
	},
	"el": {
		"spinner": {
			"loading": "Γίνεται φόρτωση..."
		}
	},
	"ro": {
		"spinner": {
			"loading": "Se încarcă"
		}
	},
	"bg": {
		"spinner": {
			"loading": "Зареждане на резултатите"
		}
	},
	"hr": {
		"spinner": {
			"loading": "Učitavanje"
		}
	}
}
</i18n>
