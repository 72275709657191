<template>
    <nav class="subheader" :class="rootClasses" role="navigation" aria-label="main-menu">
        <div class="menu-container menu-container-mobile d-md-none">
            <div class="d-flex flex-nowrap category-list">
                <nuxt-link v-for="item in categories" :key="item" class="custom-link" :to="$category.getPath(item)">
                    {{ translate(`category.${item}.name`) }}
                </nuxt-link>
            </div>
        </div>
        <div class="menu-container d-none d-md-flex">
            <UIGridContainer class="position-relative menu-container-wrapper">
                <div class="menu-left">
                    <template v-for="item in categories">
                        <a
                            :key="item"
                            :ref="`categoryitem-${item}`"
                            class="custom-link icon-link"
                            :class="{ 'is-active': activeSubmenu && category === item }"
                            tabindex="0"
                            aria-haspopup="true"
                            :aria-expanded="category === item"
                            @keyup="onKeyUp($event, item)"
                            @keydown="onKeyDown($event)"
                            @click.prevent.stop="toggleMenu(item)"
                        >
                            <span>{{ translate(`category.${item}.name`) }}</span>

                            <UIIcon
                                v-if="!activeSubmenu || category !== item"
                                class="arrow"
                                :name="['fa', 'chevron-down']"
                                :hydrate="true"
                            ></UIIcon>
                            <UIIcon
                                v-if="activeSubmenu && category === item"
                                class="arrow"
                                :name="['fa', 'chevron-up']"
                                :hydrate="true"
                            ></UIIcon>
                        </a>
                    </template>
                </div>
                <div class="menu-right">
                    <a
                        href="#"
                        class="customer-modal custom-link d-none d-md-flex"
                        @click.prevent.stop="navigateToSupport()"
                    >
                        {{ translate('support.title') }}
                    </a>
                </div>
            </UIGridContainer>
        </div>
        <div v-if="active" ref="content" class="content-container d-none d-md-block">
            <UIGridContainer class="px-3">
                <div v-for="c in filteredCategories" :key="c">
                    <UIGridRow>
                        <UIGridCol cols="3">
                            <header class="custom-border-bottom">{{ translate('category.populairProducts') }}</header>
                        </UIGridCol>
                        <UIGridCol cols="9">
                            <header class="custom-border-bottom">{{ translate('category.allProducts') }}</header>
                        </UIGridCol>
                    </UIGridRow>
                    <UIGridRow v-if="!brandsAreLoading && categoryBrands[category]">
                        <UIGridCol md="3" class="popular-brand-wrapper brands">
                            <template v-for="brand in popularBrands[c]">
                                <nuxt-link
                                    :key="brand.name"
                                    class="brand"
                                    :to="$brand.getPath(brand)"
                                    :title="$brand.getName(brand)"
                                    @click.native="toggleMenu(undefined)"
                                >
                                    <nuxt-resource
                                        :src="`images/brands/thumbnails-background/${brand.slug}.svg`"
                                        :alt="brand.name"
                                    />
                                    <span>{{ brand.name }}</span>
                                </nuxt-link>
                            </template>
                        </UIGridCol>
                        <UIGridCol md="9">
                            <div class="custom-col" role="menu">
                                <!-- hide on smaller screens above index 18 -->
                                <nuxt-link
                                    v-for="(brand, index) in sortAllBrands(categoryBrands[category])"
                                    :key="brand.name"
                                    ref="menuitem"
                                    role="menuitem"
                                    class="brand-link"
                                    :class="{ 'lg-show': index > 17 }"
                                    :to="$brand.getPath(brand)"
                                    :title="$brand.getName(brand)"
                                    @click.native="toggleMenu(undefined)"
                                    @keyup.native="onKeyUpSubmenu($event, index)"
                                    @keydown.native="onKeyDown($event)"
                                >
                                    {{ brand.name }}
                                </nuxt-link>
                            </div>
                        </UIGridCol>
                    </UIGridRow>
                    <UIGridRow v-if="brandsAreLoading && !categoryBrands[category]">
                        <UISpinner></UISpinner>
                    </UIGridRow>
                    <div>
                        <UIGridCol cols="12" class="custom-border-top link-show-all">
                            <nuxt-link
                                :to="$category.getPath(c)"
                                class="icon-link"
                                @click.native="toggleMenu(undefined)"
                            >
                                <span>{{ translate(`button.showAll`) }}</span>
                                <UIIcon class="arrow" :name="['fa', 'chevron-right']"></UIIcon>
                            </nuxt-link>
                        </UIGridCol>
                    </div>
                </div>
            </UIGridContainer>
        </div>
    </nav>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { UIGridCol, UIGridRow, UIGridContainer, UIIcon, UISpinner } from '@dundle/ui/components';
import TranslationMixin from '~/mixins/TranslationMixin';

export default {
    components: {
        UIGridCol,
        UIGridRow,
        UIGridContainer,
        UIIcon,
        UISpinner,
    },
    mixins: [TranslationMixin],

    data() {
        return {
            openMenu: false,
            category: undefined,
            isLoading: false,
            categoryBrands: {},
            popularBrands: {},
            fetchedCategoryForRegion: {},
        };
    },

    computed: {
        ...mapGetters({
            overlayElement: 'ui/overlayElement',
            activeCategories: 'data/getActiveCategories',
            activeModal: 'ui/activeModal',
            activeSubmenu: 'ui/activeSubmenu',
        }),

        active() {
            return this.activeSubmenu;
        },

        brandsAreLoading() {
            return this.isLoading;
        },

        categories() {
            return this.activeCategories;
        },

        filteredCategories() {
            return this.categories.filter(c => c === this.category);
        },

        rootClasses() {
            return {
                active: this.active,
                'apply-depth': this.active && this.overlayElement === this.$el,
            };
        },
    },

    methods: {
        ...mapActions({
            showModal: 'ui/showModal',
            showSubmenu: 'ui/showSubmenu',
            hideSubmenu: 'ui/hideSubmenu',
        }),

        async getPopularBrands(category) {
            const limit = 24;
            const categoryReponse = await this.$api.brand.getPopularPaginated(category, 0, limit);
            return categoryReponse.data;
        },

        async setBrands(category) {
            this.categoryBrands[category] = await this.getPopularBrands(category);
            const popularBrands = this.categoryBrands[category].slice(0, 4);
            this.popularBrands[category] = popularBrands;
            this.isLoading = false;
        },

        sortAllBrands(brands) {
            return brands.sort((a, b) => a.name.localeCompare(b.name));
        },

        async toggleMenu(category) {
            if (category === undefined) {
                // when undefined is passed, close menu
                this.hideSubmenu();
                this.category = undefined;
            } else if (category && this.active && this.category === category) {
                // toggle menu when category is same as previous and subnave is active

                this.hideSubmenu();
                // close menu
                this.category = undefined;

                // category item loses focus after keydown, so we need to set it again after closing
                this.$nextTick(() => {
                    this.$refs[`categoryitem-${category}`][0].focus();
                });
            } else {
                this.showSubmenu();
                this.category = category;
                // get brands
                if (this.fetchedCategoryForRegion[category] !== this.$locale.region || !this.categoryBrands[category]) {
                    this.fetchedCategoryForRegion[category] = this.$locale.region;

                    this.isLoading = true;
                    await this.setBrands(category);
                }

                // category item loses focus after keydown, so we need to set it again after closing
                this.$nextTick(() => {
                    this.$refs[`categoryitem-${category}`][0].focus();
                });
            }
        },

        navigateToSupport() {
            this.$router.push(this.$locale.path('support'));
        },

        onKeyDown(e) {
            // prevent scrolling page
            if (this.active) {
                switch (e.keyCode) {
                    case 40:
                        e.stopPropagation();
                        e.preventDefault();
                        break;
                }
            }
        },

        onKeyUp(e, category) {
            switch (e.keyCode) {
                case 13:
                    // open on enter
                    this.toggleMenu(category);
                    break;
                case 40:
                    e.stopPropagation();
                    e.preventDefault();
                    this.$nextTick(() => {
                        // arrow down -> focus on next item with role menuitem
                        this.$refs.menuitem[0].$el.focus();
                    });
                    break;
            }
        },

        onKeyUpSubmenu(e, index) {
            // interaction based on this best practice:
            // https://a11y-guidelines.orange.com/en/web/components-examples/dropdown-menu/

            switch (e.keyCode) {
                // enter is not fired, because it is a link
                // case 13:
                //     break;
                case 38:
                    // arrow up -> focus on previous item with role menuitem
                    e.stopPropagation();
                    e.preventDefault();

                    // check if target is first item, don't go to last item -> could be hidden
                    if (e.target !== this.$refs.menuitem[0].$el) {
                        this.$nextTick(() => {
                            this.$refs.menuitem[index - 1].$el.focus();
                        });
                    }
                    break;
                case 40:
                    // arrow down -> focus on next item with role menuitem
                    e.stopPropagation();
                    e.preventDefault();

                    // check if target is last item, stop
                    if (e.target !== this.$refs.menuitem[this.$refs.menuitem.length - 1].$el) {
                        this.$nextTick(() => {
                            this.$refs.menuitem[index + 1].$el.focus();
                        });
                    }
                    break;
                case 27:
                    // save the category so we can use it again to set the correct focus
                    /* eslint-disable-next-line */
                    const categoryItem = this.category;

                    // escape button -> close menu
                    this.toggleMenu(undefined);

                    // category item loses focus after keydown, so we need to set it again after closing
                    this.$nextTick(() => {
                        this.$refs[`categoryitem-${categoryItem}`][0].focus();
                    });
                    break;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.subheader {
    height: 100%;
    position: relative;

    &.apply-depth,
    &:hover {
        z-index: 1;

        .content-container {
            z-index: 0;
        }
    }

    .menu-container-wrapper {
        display: flex;
        justify-content: space-between;

        // hide the scrollbar
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
        ::-webkit-scrollbar {
            display: none;
        }
        $gradient-width: 4rem;
        .menu-left {
            display: flex;
            max-width: 87%;
            overflow-x: auto;
            .custom-link {
                margin-right: 2rem;
            }
        }
        .menu-right {
            display: flex;
            text-align: right;
            position: relative;
            &:before {
                content: '';
                display: block;
                width: $gradient-width;
                position: absolute;
                top: 0;
                height: 100%;
                left: -$gradient-width;
                pointer-events: none;
                background: linear-gradient(90deg, rgba(255, 255, 255, 0), rgba(243, 246, 248, 1));
            }
            .custom-link {
                background-color: $color-grey-1;
            }
        }
    }

    .custom-link {
        cursor: pointer;
        color: $color-header-link;
        padding: 0.5rem 0;
        font-weight: bold;
        line-height: calc(2rem - 3px);
        white-space: nowrap;

        &:hover,
        &:focus {
            color: $color-header-link-hover;
            text-decoration: none;
            outline: none;
        }

        &.is-active {
            color: $color-header-link-hover;
            border-bottom: 3px solid $color-header-link-hover;
        }
    }

    .customer-modal {
        cursor: pointer;
    }

    .content-container {
        position: absolute;
        top: calc(100% + 1px);
        left: 0;
        width: 100%;
        background-color: $color-submenu-bg;

        header {
            display: block;
            font-size: 1rem;
            margin-top: 1rem;
            margin-bottom: 0.2rem;
            color: $color-primary-dark;
            font-weight: 700;
            text-transform: uppercase;
        }

        .custom-col {
            display: flex;
            flex-flow: column wrap;
            margin-top: 0.45rem;
            margin-bottom: 0.45rem;
            max-height: 11rem;
        }
        .custom-populair-col {
            max-height: 15rem;
            display: flex;
            flex-flow: column wrap;
            margin-top: 0.45rem;
        }

        .custom-border-top {
            border-top: 1px solid $color-grey-3;
        }
        .custom-border-bottom {
            border-bottom: 1px solid $color-grey-3;
            padding-bottom: 0.45rem;
            padding-top: 1rem;
        }

        .popular-brand-wrapper.brands {
            .brand {
                display: flex;
                align-items: center;
                line-height: 2.75rem;
                color: $color-header-link;
                &:hover {
                    background-color: $color-grey-1;
                    color: $color-header-link-hover;
                }
            }
            img {
                width: auto;
                height: 2rem;
                margin-right: 0.5rem;
            }
        }

        .brand-link {
            border-radius: 3px;
            line-height: 1.75rem;
            width: 25%;
            color: $color-header-link;

            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
            &:hover,
            &:focus {
                color: $color-header-link-hover;
                text-decoration: underline;
            }
            // make sure if there are only 2 or 3 columns, on large screen they stay 3 columns
            &:last-child {
                width: 25%;

                @media screen and (min-width: 1025px) {
                    width: 50%;
                }
            }
            &.lg-show {
                display: none;

                @media screen and (min-width: 1025px) {
                    display: -webkit-box;
                }
            }
        }

        .all-products-row {
            max-height: 16rem;
        }
    }
    .link-show-all {
        font-weight: bold;
        display: flex;
        justify-content: center;
        line-height: 3rem;
    }

    a.icon-link {
        display: flex;
        align-items: baseline;
        svg {
            margin-left: 0.4rem;
            font-size: 0.7rem;
        }
    }

    .menu-container-mobile {
        .category-list {
            overflow-y: hidden;
            /* Hide scrollbar for Chrome, Safari and Opera */
            &::-webkit-scrollbar {
                display: none;
            }

            /* Hide scrollbar for IE and Edge */
            -ms-overflow-style: none;
        }
        a.custom-link {
            min-width: fit-content;
            margin: 0;
            padding: 0.5rem 1rem;
        }
    }
}
</style>
<i18n src="@dundle/locale/data/support.json"></i18n>
<i18n src="@dundle/locale/data/categories.json"></i18n>
<i18n src="@dundle/locale/data/buttons.json"></i18n>
