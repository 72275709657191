<template>
    <div class="ui-modal-container" :class="{ active: activeModal }" @mousedown="onMouseDown">
        <div class="wrapper">
            <transition :name="activeModal ? activeModal.transition : ''" duration="500" mode="out-in" tag="div">
                <UIModal v-if="activeModal" :class="modalClass">
                    <slot></slot>
                </UIModal>
            </transition>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { UIModal } from '@dundle/ui/components';

export default {
    components: {
        UIModal,
    },

    computed: {
        ...mapGetters({
            activeModal: 'ui/activeModal',
        }),

        modalClass() {
            if (this.activeModal.showMenu) {
                return 'large-modal menu-free';
            } else if (this.activeModal.isMediumModal) {
                return 'medium-modal';
            } else if (this.activeModal.isLargeModal) {
                return 'large-modal';
            } else {
                return '';
            }
        },
    },

    methods: {
        ...mapActions({
            removeFromCart: 'cart/removeItem',
            hideModal: 'ui/hideModal',
        }),
        onMouseDown() {
            if (this.activeModal.canClickAway) {
                if (this.activeModal.product) {
                    this.removeFromCart(this.activeModal);
                }
                this.hideModal();
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.ui-modal-container {
    display: none;

    z-index: 11; // needed to put above overlay

    &.active {
        display: block;
        position: fixed;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
    }

    .wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
    }
}

// SLIDE IN

.slide-fade-enter-active,
.slide-fade-leave-active {
    transition: all 0.3s ease;
}

.slide-fade-enter,
.slide-fade-leave-active {
    transform: translateY(600px);
}
</style>
