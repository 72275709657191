<template>
    <div class="banner" :class="type" @click="navigateToLink">
        <slot />
        <UIOverlay v-if="overlayIsActive && overlayMenuIsActive" id="overlay" class="menu-overlay" />
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { UIOverlay } from '@dundle/ui/components';
export default {
    components: { UIOverlay },
    props: {
        link: {
            type: String,
            required: false,
        },
        type: {
            type: String,
            required: true,
            validator: value => {
                return ['homepage', 'app'].includes(value);
            },
        },
    },
    computed: {
        ...mapGetters({
            overlayIsActive: 'ui/overlayIsActive',
            overlayMenuIsActive: 'ui/overlayMenuIsActive',
        }),
    },
    methods: {
        navigateToLink() {
            if (this.link) window.open(this.link, '_blank');
        },
    },
};
</script>

<style lang="scss" scoped>
.banner {
    position: relative;
    height: 2rem;
    background-color: $color-top-notification-bg;
    width: 100%;

    display: flex;
    justify-content: center;
    align-items: center;

    &.homepage {
        background-color: $color-primary-dark;

        @media (prefers-color-scheme: dark) {
            background-color: $color-top-notification-bg;
        }
    }
}
</style>
