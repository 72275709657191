<template>
    <div class="menu-breadcrumb">
        <nuxt-link class="breadcrumb" :to="breadcrumb.link">
            <UIIcon :name="['fal', 'arrow-left']" class="breadcrumb-arrow"></UIIcon>
            <UIIcon v-if="breadcrumb.name === 'home'" :name="['fas', 'home']" class="ml-2 home-arrow"></UIIcon>

            <span v-else class="breadcrumb-title text-small ml-2">{{ breadcrumb.name }}</span>
        </nuxt-link>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { UIIcon } from '@dundle/ui/components';

export default {
    components: {
        UIIcon,
    },

    computed: {
        ...mapGetters({
            breadcrumbs: 'breadcrumb/getItems',
        }),

        breadcrumb() {
            // fallback for the category pages
            if (this.breadcrumbs.length === 2) {
                return {
                    name: 'home',
                    link: '/',
                };
            }
            return this.breadcrumbs[this.breadcrumbs.length - 2];
        },
    },
};
</script>
<style lang="scss" scoped>
.menu-breadcrumb {
    height: 100%;
    line-height: 3rem;
    position: relative;

    a:hover,
    a:active {
        text-decoration: none;
    }

    .breadcrumb-arrow {
        text-align: center;
        font-size: 1rem;
    }
}
</style>
