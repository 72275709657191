<template>
    <div class="menu-wrapper" :class="{ 'is-scrolling': isScrolling }">
        <UITopNotificationBanner
            id="app-promotion"
            :type="notificationType"
            :link="supportsPromotion('app') ? linkToApp : ''"
            @click="$event => clickOnBanner()"
        >
            <p v-if="isMarketingRegion('app') && isMarketingLanguage('app')" class="text">
                {{ translate('app.promotion.bannerText') }}
            </p>
            <MenuPromotion v-else />
        </UITopNotificationBanner>
        <slot :is-scrolling="isScrolling" />
    </div>
</template>

<script>
import { UITopNotificationBanner } from '@dundle/ui/components';
import MenuPromotion from './MenuPromotion.vue';
import TranslationMixin from '~/mixins/TranslationMixin';
import MarketingMixin from '~/mixins/MarketingMixin';

export default {
    components: { UITopNotificationBanner, MenuPromotion },
    mixins: [TranslationMixin, MarketingMixin],
    data() {
        return {
            isScrolling: false,
            linkToApp: '',
        };
    },
    computed: {
        notificationType() {
            return this.supportsApp && this.isAppRegion ? 'app' : 'homepage';
        },
    },

    mounted() {
        window.addEventListener('scroll', this.scrollListener);

        // Call once:
        this.scrollListener();
        if (this.$device.isIOS()) {
            this.linkToApp = 'https://apps.apple.com/app/dundle-prepaid-cards-egifts/id1605958640';
        } else {
            this.linkToApp = 'https://play.google.com/store/apps/details?id=com.dundle.app';
        }
    },

    beforeDestroy() {
        window.removeEventListener('scroll', this.scrollListener);
    },

    clickOnBanner() {
        if (this.linkToApp) {
            this.$analytics.event('view_promotion', {
                creative_name: 'Header Banner',
                creative_slot: 'header_banner',
                promotion_id: 'app_promotion_default',
                promotion_name: 'App Promotion Standard',
            });
        }
    },

    methods: {
        scrollListener() {
            if (this.isScrolling) {
                if (window.scrollY <= 0) {
                    this.isScrolling = false;
                }
            } else if (window.scrollY > 0) {
                this.isScrolling = true;
            }
        },
        trackGooglePlayDownload() {
            this.$gtm?.push({ event: 'event_app_download_google_play_header_banner' });
        },
    },
};
</script>

<style lang="scss" scoped>
.menu-wrapper {
    top: 0;
    right: 0;
    left: 0;
    z-index: 11;
    position: sticky;
    display: block;

    box-shadow: 0 1px 3px rgba(0, 0, 0, 0);
    transition: box-shadow 0.2s ease;

    &.is-scrolling {
        box-shadow: 0 -1px 10px 0 rgba(33, 34, 38, 0.31);
    }

    .text {
        color: $color-bg;
        margin-bottom: 0;
        font-size: 0.75rem;

        @media screen and (min-width: 768px) {
            font-size: 1rem;
        }
    }
}
</style>

<i18n src="@dundle/locale/data/app-promotion.json"></i18n>
