<template>
    <div class="promotion-wrapper container">
        <template v-for="(element, index) in promotionElements">
            <p :key="index" :class="shownElement === index ? 'show' : 'hide'" class="promotion">
                <span class="icon">{{ element.icon }}</span>
                <span class="text">{{ element.text }}</span>
                <span class="icon d-md-none"> {{ element.icon }} </span>
            </p>
        </template>
    </div>
</template>

<script>
import TranslationMixin from '~/mixins/TranslationMixin';
export default {
    mixins: [TranslationMixin],
    data() {
        return {
            elementToShow: 0,
            animationInit: false,
        };
    },
    mounted() {
        if (!this.animationInit) this.playAnimation();
    },
    computed: {
        shownElement() {
            return this.elementToShow;
        },
        promotionElements() {
            return [
                {
                    icon: '⚡',
                    text: this.translate('usp.2'),
                },
                {
                    // TODO: wissel deze shit met de eerste
                    icon: '💰',
                    text: this.translate('usp.1'),
                },
                {
                    icon: '🏆',
                    text: this.translate('usp.3'),
                },
            ];
        },
    },
    methods: {
        playAnimation() {
            if (this.animationInit === false) this.animationInit = true;
            setTimeout(() => {
                if (this.elementToShow + 1 >= this.promotionElements.length) {
                    this.elementToShow = 0;
                } else {
                    this.elementToShow++;
                }
                this.playAnimation();
            }, 6000);
        },
    },
};
</script>

<style lang="scss" scoped>
@keyframes slidein {
    from {
        transform: translateY(-100%);
        opacity: 0;
    }

    to {
        transform: translateY(0%);
        opacity: 1;
    }
}

@keyframes slideout {
    from {
        transform: translateY(0%);
        opacity: 1;
    }

    to {
        transform: translateY(-100%);
        opacity: 0;
    }
}

.promotion-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;

    @media screen and (max-width: 767.9px) {
        padding: 0;
    }

    // desktop styling
    @media screen and (min-width: 768px) {
        display: flex;
        justify-content: space-around;
    }
}

.promotion {
    position: absolute;
    height: 100%;
    width: 100%;

    display: flex;
    justify-content: center;
    align-items: center;

    .text {
        font-size: 0.8rem;
        color: #ffffff;
        margin-bottom: 0;
    }

    .icon {
        margin: 0 4px;
    }

    @media screen and (max-width: 767.9px) {
        // animation
        // transform: translateY(-100%);
        &.show {
            animation: slidein 0.5s;
            transform: translateY(0%);
            opacity: 1;
        }

        &.hide {
            animation: slideout 0.5s;
            transform: translateY(-100%);
            opacity: 0;
        }
    }

    // desktop styling
    @media screen and (min-width: 768px) {
        position: relative;
        width: auto;
    }
}
</style>

<i18n></i18n>
