<template>
    <div class="ui-modal" :class="activeModal.name" @mousedown.stop>
        <button class="close" @click="onClickClose">
            <UIIcon :name="['fal', 'times']" />
        </button>
        <slot></slot>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { UIIcon } from '@dundle/ui/components';

export default {
    components: {
        UIIcon,
    },

    mounted() {
        document.addEventListener('keydown', this.escapeListener);
    },

    destroyed() {
        document.removeEventListener('keydown', this.escapeListener);
    },

    computed: {
        ...mapGetters({
            activeModal: 'ui/activeModal',
        }),
    },

    methods: {
        ...mapActions({
            removeFromCart: 'cart/removeItem',
            hideModal: 'ui/hideModal',
        }),

        escapeListener(e) {
            if (this.activeModal.canClickAway && e.keyCode === 27) {
                if (this.activeModal.product) {
                    this.removeFromCart(this.activeModal);
                }
                this.hideModal();
            }
        },

        onClickClose() {
            if (this.activeModal.product) {
                this.removeFromCart(this.activeModal);
            }
            this.hideModal();
        },
    },
};
</script>

<style lang="scss" scoped>
.ui-modal {
    background-color: $color-bg;
    width: 25rem;
    max-width: 25rem;
    padding: 2rem;
    box-shadow: 0 1px 1rem rgba(0, 0, 0, 0.3);
    border-radius: $border-radius-default;
    position: relative;

    z-index: 1; // needed to prevent click on the uiModalContainer

    &.moved {
        .close {
            right: 1.9rem;
            top: 0.6rem;
            color: #fff;
            background: rgba(0, 0, 0, 0.3);
            opacity: 1;
        }
    }

    &.medium-modal {
        position: relative;
        width: 31.333rem;
        max-width: 100vw;
    }

    &.large-modal {
        position: relative;
        height: 40rem;
        width: 50rem;
        max-width: 100vw;
        max-height: 100%;

        &.menu-free {
            max-height: calc(100% - 4rem);

            @media screen and (max-width: 768px) {
                margin-top: $x-menu-bar-height;
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;
            }

            @media screen and (min-width: 768px) {
                width: 60rem;
                height: 48rem;

                overflow-x: hidden;
            }
        }

        ::v-deep .scrolling-modal {
            height: calc(100% + 1rem);

            article {
                margin-left: -2rem;
                margin-right: -2rem;
                border-top: 1px solid $color-grey-3;
                padding: 1rem;
                overflow-y: auto;
                height: 100%;
            }
        }

        @media screen and (max-width: 767px) {
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
        }
    }

    &.fraud-warning {
        @media screen and (max-width: 768px) {
            overflow-y: scroll;
        }
    }

    &.login,
    &.verify {
        @media screen and (max-width: 767px) {
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            width: 100vw;
            max-width: 100vw;
        }
    }

    &.gift {
        overflow-y: scroll;

        padding-left: 1rem;
        padding-right: 1rem;
    }

    ::v-deep .modal-header {
        margin: -2rem -2rem 0 -2rem;
        min-height: 3rem;
        width: calc(100% + 4rem);
        line-height: 1.6rem;
        color: $color-grey-7;
        font-weight: bold;
        font-size: 1.25rem;
        padding: 0.7rem 0 0.7rem 1rem;

        &.success {
            background: rgba($color-validation, 0.1);
            color: $color-validation;
            border-bottom: 1px solid rgba($color-validation, 0.05);
            padding-right: 3rem;
        }
        &.info {
            background: $color-grey-1;
            color: $color-text;
            border-bottom: 1px solid $color-grey-3;
            padding-right: 3rem;
        }
    }

    ::v-deep h1 {
        margin: 0 0 0.25rem 0;
    }

    button.close {
        position: absolute;
        top: 0rem;
        right: 0rem;
        width: 3rem;
        height: 3rem;
    }

    @media screen and (max-width: 767px) {
        max-height: 100%;
    }
}
</style>
