<template>
    <div class="x-submenu w-100" :class="{ 'd-none': !showSteps, 'd-md-block': true }">
        <transition name="fade" duration="200" mode="out-in" tag="div">
            <component :is="subHeaderType" />
        </transition>
        <!-- <transition name="fade" duration="200" mode="out-in">
            <MenuBreadcrumb class="d-md-none" v-if="showBreadrumbs" />
        </transition> -->
        <UIOverlay v-if="overlayIsActive && overlayMenuIsActive" id="overlay" class="menu-overlay"></UIOverlay>
    </div>
</template>

<script>
import { UIOverlay } from '@dundle/ui/components';
import { mapGetters } from 'vuex';
import { MenuSubNav, MenuBreadcrumb } from '~/components/menu';

export default {
    components: {
        MenuSubNav,
        MenuBreadcrumb,
        UIOverlay,
        Steps: () => import('~/components/layout/Steps'),
    },

    computed: {
        ...mapGetters({
            currentStepId: 'route/getStep',
            breadcrumbs: 'breadcrumb/getItems',
            overlayMenuIsActive: 'ui/overlayMenuIsActive',
            overlayIsActive: 'ui/overlayIsActive',
        }),

        subHeaderType() {
            if (this.showSteps) {
                return 'Steps';
            } else {
                return 'MenuSubNav';
            }
        },

        step() {
            return this.currentStepId;
        },

        showSteps() {
            return typeof this.step !== 'undefined' && !!this.step && this.step !== 'return';
        },

        showBreadrumbs() {
            return this.breadcrumbs.length > 2;
        },
    },
};
</script>

<style lang="scss" scoped>
.x-submenu {
    height: 3rem;
    position: relative;
    background: $color-subheader;
    //backdrop-filter: saturate(180%) blur(20px);
    border-top: 1px solid transparent;
    border-bottom: 1px solid $color-subheader-border;
    height: 3rem;
    position: relative;

    @media (min-width: 1200px) {
        // Add this back later:
        // & ::v-deep .ui-container {
        //     max-width: 1200px;
        // }
    }

    .dundle & {
        border-top: 1px solid $color-subheader-border;
    }

    .dundle .is-scrolling & {
        border-bottom: 1px solid $color-grey-3;
    }

    &::after {
        display: block;
        content: '';
        clear: both;
    }

    overflow: visible;

    &::-webkit-scrollbar {
        display: none;
    }
}
</style>
