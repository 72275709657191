<template>
    <transition name="fade" duration="3000" tag="div">
        <div v-if="isActive" class="x-overlay" @click="hideOverlays">&nbsp;</div>
    </transition>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
    computed: mapGetters({
        isActive: 'ui/overlayIsActive',
    }),

    methods: {
        ...mapActions({
            show: 'ui/showOverlay',
            hide: 'ui/hideOverlay',
            hideSubmenu: 'ui/hideSubmenu',
            hideSearch: 'ui/hideSearch',
            hideModal: 'ui/hideModal',
        }),
        hideOverlays() {
            this.hide();
            this.hideSubmenu();
            this.hideSearch();
            this.hideModal();
        },
    },
};
</script>

<style lang="scss" scoped>
.x-overlay {
    display: block;
    position: absolute;
    height: 100%;
    left: 0;
    top: 0;
    width: 100%;
    transition: opacity 0.25s ease-in-out;
    background-color: rgba(0, 0, 5, 0.75);
    opacity: 1;
    z-index: 1;

    &.menu-overlay {
        height: calc(100% + 1px);
    }

    &.fade-leave-active {
        pointer-events: none;
    }

    &.fade-leave-to {
        opacity: 0;
    }
}
</style>
