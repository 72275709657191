<template>
    <div class="menu-item" :class="classes">
        <UIRipple
            class="version px-2 toggle-item d-flex h-100 align-items-center justify-content-center"
            tabindex="0"
            color="black"
            :opacity="0.15"
            :speed="3"
            @click.native="onClick()"
            @keydown.native="onEnter"
        >
            <div class="d-flex align-items-center justify-content-center position-relative">
                <slot name="icon"></slot>
            </div>
            <div class="pl-2 text d-none d-md-flex flex-column position-relative">
                <p class="font-weight-bold">
                    <slot name="title"></slot>
                </p>
                <p>
                    <slot name="details"></slot>
                </p>
            </div>
        </UIRipple>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import { UIRipple } from '@dundle/ui/components';

export default {
    components: {
        UIRipple,
    },
    props: ['to'],

    data() {
        return {
            active: false,
        };
    },

    computed: {
        classes() {
            const classList = [];

            if (this.active) {
                classList.push('active');
            }

            return classList;
        },
    },

    methods: {
        onClick() {
            this.$emit('click');
        },

        onFocus() {
            // TODO lazy load dropdown component
        },

        onBlur() {
            this.deactivate();
        },

        activate() {
            if (this.$device.isMobile()) {
                this.$emit('click');
            } else {
                this.active = true;
                this.showOverlay();
            }
        },

        deactivate() {
            this.hideOverlay();
            this.active = false;
        },

        onEnter(ev) {
            if (ev.key === 'Enter') {
                this.$emit('click');
            }
        },

        ...mapActions({
            showOverlay: 'ui/showOverlay',
            hideOverlay: 'ui/hideOverlay',
            showPopup: 'ui/showPopup',
            hidePopup: 'ui/hidePopup',
        }),
    },
};
</script>

<style lang="scss" scoped>
.menu-item {
    font-size: 1rem;
    list-style-type: none;
    position: relative;
    height: 100%;
    text-decoration: none;

    .version {
        color: $color-header-text;
        position: relative;
        height: 2.9rem;
        padding: 0 0.5rem;
        border-radius: 3px;
        cursor: pointer;
        overflow: hidden;
        z-index: 1;

        .font-weight-bold {
            color: $color-header-text-bold;
        }

        svg {
            height: 2.2rem;
            path {
                fill: $color-header-text-bold;
            }
        }

        .cart-open {
            position: relative;
            top: -2px;
        }

        box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.25);
        transition: box-shadow 0.15s ease-in-out;

        &:focus {
            outline: none;
        }

        &:active {
            outline: none;
            box-shadow: none;
        }

        &.toggle-item {
            .text {
                p {
                    line-height: 1rem;
                    margin-bottom: 0;
                    margin-right: 0.5rem;
                }
                & p:nth-child(2) {
                    font-size: 0.9rem;
                }
            }

            &.active {
                position: relative;
                z-index: 0;
                background-color: $color-submenu-bg;
                color: $color-primary;

                svg {
                    color: $color-primary;
                }
            }
        }
    }
}
</style>
