<template>
    <div class="menu-item" :class="{ active: active }">
        <UIRipple
            class="version px-3 toggle-item d-flex h-100 align-items-center justify-content-center"
            tabindex="0"
            color="black"
            :opacity="0.15"
            :speed="3"
            @click.native="onClick()"
            @blur.native="onBlur()"
            @keydown.native="onEnter"
        >
            <div class="d-flex align-items-center justify-content-center position-relative">
                <slot name="icon"></slot>
            </div>
            <div class="pl-2 text d-none d-md-flex flex-column position-relative">
                <p class="font-weight-bold">
                    <slot name="title"></slot>
                </p>
                <p>
                    <slot name="details"></slot>
                </p>
            </div>
        </UIRipple>

        <slot v-if="active" name="dropdown" @hide="hide"></slot>
        <slot name="popup"></slot>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { UIRipple } from '@dundle/ui/components';

export default {
    components: {
        UIRipple,
    },

    data() {
        return {
            active: false,
        };
    },
    computed: {
        ...mapGetters({
            activeModal: 'ui/activeModal',
        }),
    },
    methods: {
        onClick() {
            if (!this.active) {
                this.activate();
            } else {
                this.deactivate();
            }
        },

        onBlur() {
            if (!this.activeModal) this.deactivate();
        },

        activate() {
            if (this.$device.isMobile()) {
                this.$emit('click');
            } else {
                this.active = true;
                this.hideSubmenu();
                this.showOverlay();
            }
        },

        deactivate() {
            this.hideOverlay();
            this.active = false;
        },

        onEnter(ev) {
            if (ev.key === 'Enter') {
                this.activate();
            }
        },

        ...mapActions({
            showOverlay: 'ui/showOverlay',
            hideOverlay: 'ui/hideOverlay',
            showPopup: 'ui/showPopup',
            hidePopup: 'ui/hidePopup',
            hideSubmenu: 'ui/hideSubmenu',
        }),
    },
};
</script>

<style lang="scss" scoped>
@use 'sass:math';

.menu-item {
    font-size: 1rem;
    list-style-type: none;
    position: relative;
    height: 100%;

    .version {
        color: $color-header-text;
        position: relative;
        height: 2.9rem;
        padding: 0 0.5rem;
        border-radius: 3px;
        cursor: pointer;
        overflow: hidden;

        svg {
            height: 2.2rem;
        }

        .cart-open {
            position: relative;
            top: -2px;
        }

        box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.25);
        transition: box-shadow 0.15s ease-in-out;

        // violation of a11y rules. Never hide focus styling. Make it pretty
        // &:focus {
        //     outline: none;
        // }

        &:active {
            outline: none;
            box-shadow: none;
        }

        &.toggle-item {
            .font-weight-bold {
                color: $color-header-text-bold;
            }

            .text {
                p {
                    line-height: 1rem;
                    margin-bottom: 0;
                }
                & p:nth-child(2) {
                    font-size: 0.9rem;
                }
            }

            &.active {
                position: relative;
                z-index: 0;
                background-color: $color-submenu-bg;
                color: $color-primary;

                svg {
                    color: $color-primary;
                }
            }
        }
    }
}
</style>
