<template>
    <div id="main-container" class="main-container dundle">
        <!-- for paypal payment method -->
        <div v-if="fullScreenLoadingIsActive" class="full-screen-loading" @click.stop.prevent>
            <UILoadingIndicator />
        </div>

        <MenuWrapper :class="{ 'active-overlay': overlayIsActive }">
            <MenuBar ref="menu" @menu:open="onMenuOpen" @menu:close="onMenuClose" />
            <div class="position-relative content-container-wrapper">
                <MenuSubHeader />
            </div>
        </MenuWrapper>
        <main class="content-wrapper">
            <div id="content-container" class="content-container">
                <SeoStructuredDataBreadcrumb :data="breadcrumbItems" />

                <LayoutBreadcrumb v-show="showLayoutBreadcrumbs" />

                <div id="content" class="content">
                    <nuxt />
                </div>

                <FooterComponent />

                <UIOverlay
                    v-if="overlayIsActive || menuIsActive"
                    id="overlay"
                    :class="{ 'menu-is-active': menuIsActive }"
                />
            </div>

            <UIModalContainer>
                <ModalContent />
            </UIModalContainer>

            <UISwipeContainer
                ref="swiper"
                @pan:start="onPanStart"
                @pan:end="onPanEnd"
                @side:open="onSideOpen"
                @side:close="onSideClose"
            >
                <UISwipePane side="left">
                    <transition duration="500" tag="div">
                        <MenuPane v-if="menuIsActive" ref="menuPane" @menu:close="onMenuClose" />
                    </transition>
                </UISwipePane>
            </UISwipeContainer>
        </main>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { UIOverlay, UIModalContainer, UISwipeContainer, UISwipePane, UILoadingIndicator } from '@dundle/ui/components';
import TranslationMixin from '~/mixins/TranslationMixin';
import LayoutBreadcrumb from '~/components/layout/LayoutBreadcrumb';
import { MenuBar, MenuWrapper, MenuSubHeader } from '~/components/menu';
import SeoStructuredDataBreadcrumb from '~/components/seo/SeoStructuredDataBreadcrumb';
import ModalContent from '~/components/modal/ModalContent';

export default {
    components: {
        UISwipeContainer,
        UISwipePane,
        MenuBar,
        MenuPane: () => import('~/components/menu/MenuPane'),
        MenuWrapper,
        UIOverlay,
        FooterComponent: () => import('~/components/footer/FooterComponent'),
        MenuSubHeader,
        UIModalContainer,
        // UIModal, // Keep this because of some webpack build bug... Ask @Tim
        LayoutBreadcrumb,
        ModalContent,
        // SettingsRedirectionHeader: () => import('~/components/settings/SettingsRedirectionHeader'),
        SeoStructuredDataBreadcrumb,
        UILoadingIndicator,
    },

    mixins: [TranslationMixin],

    data() {
        return {
            isScrolling: false,
        };
    },
    head(app) {
        const shouldHaveSlash = this.$store.getters['route/getSlash'];

        if (this.$route.matched.length === 0) {
            return;
        }

        const route = this.$locale.reverseRoute(this.$route);

        if (typeof route === 'undefined') {
            return;
        }

        return {
            __dangerouslyDisableSanitizers: ['script'],

            htmlAttrs: {
                lang: this.$locale.language + '-' + this.$locale.regio.toUpperCase(),
            },

            title: this.$locale.getGlobal('websiteName'),

            meta: [
                {
                    hid: 'google-verification',
                    name: 'google-site-verification',
                    content: process.env.googleSiteVerification,
                },
                {
                    hid: 'content-language',
                    'http-equiv': 'content-language',
                    content: this.$locale.iso.toLowerCase(),
                },
            ],

            bodyAttrs: {
                class: this.bodyClass,
            },

            link: [
                {
                    hid: 'canonical',
                    rel: 'canonical',
                    href: this.$locale.url({
                        ...this.$locale.reverseRoute(this.$route),
                        query: undefined,
                        deriveDomain: true,
                        trailingSlash: shouldHaveSlash,
                    }),
                },
            ],
        };
    },
    computed: {
        ...mapGetters({
            overlayIsActive: 'ui/overlayIsActive',
            menuIsActive: 'ui/menuIsActive',
            menuPan: 'ui/menuPan',
            menuIsPanning: 'ui/menuIsPanning',
            activeModal: 'ui/activeModal',
            redirectionHeader: 'ui/activeRedirectionHeader',
            sessionAcceptsCookies: 'session/acceptsCookies',
            sessionIsInitialized: 'session/isInitialized',
            scrollPaddingRight: 'ui/scrollPaddingRight',
            scrollIsActive: 'ui/scrollIsActive',
            sessionCreatedAt: 'session/createdAt',
            showLayoutBreadcrumbs: 'breadcrumb/showLayoutBreadcrumbs',
            breadcrumbItems: 'breadcrumb/getItems',
            fullScreenLoadingIsActive: 'ui/fullScreenLoadingIsActive',
        }),

        showCookieBanner() {
            if (process.server) {
                return true;
            }

            if (!this.sessionIsInitialized) {
                return true;
            }

            return !this.sessionAcceptsCookies;
        },

        scrollIsDisabled() {
            return this.menuIsActive || this.activeModal.name;
        },

        bodyClass() {
            if (!this.scrollIsActive) {
                return 'noscroll';
            }
            return null;
        },
    },

    watch: {
        $route(to, from) {
            if (this.routeChangeFromMenu) {
                this.routeChangeFromMenu = false;
            } else if (to.hash !== '#view=menu' && this.$refs.swiper && this.$refs.swiper.isActive) {
                this.$refs.swiper.closeSide();
            }
        },
    },
    mounted() {
        if (this.$route.hash === '#view=menu') {
            this.onMenuOpen();
        }
    },
    methods: {
        onPanStart() {
            this.showMenuOverlay();

            this.startMenuPanning();
        },

        onPanEnd() {
            if (!this.$refs.swiper.isActive) {
                this.hideOverlay();
            }

            this.stopMenuPanning();
        },

        onSideOpen(side) {
            this.showMenuOverlay();
            // disableBodyScroll();

            if (side === 'left') {
                this.$nextTick(() => this.showMenu());

                setTimeout(() => {
                    this.routeChangeFromMenu = true;
                    if (!this.$route.fullPath.includes('#view=menu')) {
                        this.$router.push(this.$route.fullPath + '#view=menu');
                    }
                }, 500);
            }
        },

        onSideClose() {
            // enableBodyScroll();

            this.hideOverlay();
            this.hideMenu();

            if (this.$route.hash.includes('view')) {
                setTimeout(() => {
                    this.routeChangeFromMenu = true;
                    this.$router.push({
                        ...this.$route,
                        hash: undefined,
                    });
                    if (this.$refs.menuPane) {
                        this.$refs.menuPane.resetView();
                    }
                }, 250);
            } else if (this.$refs.menuPane) {
                this.$refs.menuPane.resetView();
            }
        },

        onMenuOpen() {
            this.$refs.swiper.openSide('left');
        },

        onMenuClose() {
            this.$refs.swiper.closeSide();
        },

        ...mapActions({
            showMenu: 'ui/showMenu',
            hideMenu: 'ui/hideMenu',
            showModal: 'ui/showModal',
            showMenuOverlay: 'ui/showMenuOverlay',
            hideOverlay: 'ui/hideOverlay',
            startMenuPanning: 'ui/startMenuPanning',
            stopMenuPanning: 'ui/stopMenuPanning',
        }),
    },
};
</script>

<style lang="scss" scoped>
html,
body,
#menu {
    min-width: 320px;
}

// for paypal payment method
.full-screen-loading {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    z-index: 12;
    align-items: center;
    justify-content: space-around;
    background-color: rgba(0, 0, 0, 0.8);
    background: -webkit-radial-gradient(50% 50%, ellipse closest-corner, rgba(0, 0, 0, 1) 1%, rgba(0, 0, 0, 0.75) 100%);
}

#sticky-submenu {
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.25);
    position: sticky;
    top: 70px;
    z-index: 1;

    box-shadow: 0 1px 3px rgba(0, 0, 0, 0);
    transition: box-shadow 0.2s ease;

    ::v-deep .menu-container {
        background: $color-grey-1 !important;
        border-bottom: none !important;
    }

    &.is-scrolling {
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.25);
        border-bottom: 1px solid rgba(255, 255, 255, 0.85);
    }
}

.main-container {
    margin: 0 0 0 0;
    display: flex;
    min-height: 100vh;
    flex-direction: column;
    width: 100%;

    .active-overlay .content-container-wrapper {
        background: #fff;
    }

    .card-header {
        padding: 0;
    }

    .content-wrapper {
        position: relative;
        display: flex;
        flex-direction: column;
        flex: 1;
    }

    .content-container {
        position: relative;
        display: flex;
        flex-direction: column;
        flex: 1;
    }
}

@media (min-width: 768px) {
    .content {
        padding-top: 1rem;
    }
}

@media screen and (min-width: 768px) {
    .content {
        padding: 2rem 0;
    }
}
.content {
    position: relative;
    flex-grow: 1;
}
</style>
