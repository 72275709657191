<template>
    <div class="x-swipe-pane" :class="classes">
        <slot></slot>
    </div>
</template>

<script>
export default {
    props: {
        side: {
            type: String,
        },
    },

    computed: {
        classes() {
            return 'side-' + this.side;
        },
    },
};
</script>

<style lang="scss" scoped>
.x-swipe-pane {
    background-color: $color-grey-1;

    &.side-left {
        position: absolute;
        width: calc(100% - 4rem);
        height: 100%;
    }

    &.side-right {
        position: absolute;
        top: 0;
        right: calc(-100% + 4rem);
        width: calc(100% - 4rem);
    }
}
</style>
