<template>
    <UIGridContainer v-if="items.length >= 2">
        <nav class="breadcrumb d-none d-md-flex align-items-center" aria-label="breadcrumb">
            <nuxt-link :to="firstItem.link || '/'" :title="firstItem.name">
                <UIIcon class="icon-home" :name="['fal', 'home']" />
            </nuxt-link>

            <div v-for="item in middleItems" :key="item.link">
                <span class="separator small">
                    <UIIcon :name="['fal', 'chevron-double-right']" />
                </span>
                <nuxt-link :to="item.link || '/'">
                    <span>{{ item.name }}</span>
                </nuxt-link>
            </div>

            <div>
                <span class="separator small">
                    <UIIcon :name="['fal', 'chevron-double-right']" />
                </span>
                <span class="text-light">
                    {{ lastItem.name }}
                </span>
            </div>
        </nav>

        <MenuBreadcrumb class="d-md-none" />
    </UIGridContainer>
</template>
<script>
import { mapGetters } from 'vuex';
import { UIIcon, UIGridContainer } from '@dundle/ui/components';
import { MenuBreadcrumb } from '~/components/menu';

export default {
    components: {
        UIIcon,
        UIGridContainer,
        MenuBreadcrumb,
    },

    computed: {
        ...mapGetters({
            items: 'breadcrumb/getItems',
            isLayoutBreadcrumb: 'breadcrumb/showLayoutBreadcrumbs',
        }),

        firstItem() {
            return this.items[0];
        },

        middleItems() {
            return this.items.slice(1, this.items.length - 1);
        },

        lastItem() {
            return this.items[this.items.length - 1];
        },
    },
};
</script>
<style lang="scss" scoped>
.breadcrumb {
    margin-top: 1rem;

    .separator {
        font-size: 0.75rem;
        margin-left: 0.25rem;
    }

    @media screen and (min-width: 768px) {
    }
}
.icon-home {
    font-size: 1rem;
}
</style>
