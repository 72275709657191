<template>
    <span class="ui-flag" :class="{ 'no-shadow': code === 'ww' }">
        <nuxt-resource :lazy="lazy" :src="`images/flags/${code.toLowerCase()}.svg`" :alt="code" />
    </span>
</template>

<script>
export default {
    props: {
        code: {
            default: false,
        },
        lazy: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style lang="scss" scoped>
.ui-flag {
    display: inline-block;
    height: 1rem;
    width: 1.333333rem;
    opacity: 1;
    position: relative;
    vertical-align: middle;
    align-self: center;
    top: -1px;

    img {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }

    &:not(.no-shadow)::after {
        display: block;
        content: ' ';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        box-shadow: inset 0 0 1px 1px rgba(0, 0, 0, 0.3);
    }

    &.large {
        height: 1.5rem;
        width: 2rem;

        &::after {
            box-shadow: inset 0 0 1px 1px rgba(0, 0, 0, 0.4);
        }
    }
}
</style>
