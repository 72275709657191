<template>
    <div class="x-menu-bar" :class="classes">
        <slot></slot>
    </div>
</template>

<script>
export default {
    props: {
        variant: {
            type: String,
            default: 'default',
        },
    },

    computed: {
        classes() {
            return this.variant;
        },
    },
};
</script>

<style lang="scss" scoped>
.x-menu-bar {
    position: relative;
    height: $x-menu-bar-height;
    line-height: $x-menu-bar-height;
    background-color: $color-header;
    box-shadow: $color-header-shadow;
    border-bottom: 1px solid $color-header-border;
    display: flex;

    &.transparent {
        background-color: transparent;
        box-shadow: none;
        border-bottom: 1px solid $color-grey-3;
    }
}
</style>
