<template></template>

<script>
export default {
    props: ['data'],

    computed: {
        mapItems() {
            return this.data
                .map((breadcrumb, index) => {
                    // check if properties exist and skip if link is homepage link
                    if (breadcrumb.hasOwnProperty('name')) {
                        // build the breadcrumb listItem structured data type
                        return {
                            '@type': 'ListItem',
                            position: index + 1,
                            name: breadcrumb.name,
                            item: breadcrumb.url,
                        };
                    }
                })
                .filter(item => item);
        },
    },

    head() {
        if (this.data.length > 0) {
            // create root properties for the breadcrumb structured data type
            const structuredDataObject = {
                '@context': 'https://schema.org',
                '@type': 'BreadcrumbList',
            };

            if (this.mapItems.length === this.data.length) {
                // filter nul values from mapped result and put them in mainentity property
                structuredDataObject.itemListElement = this.mapItems;
                return {
                    script: [
                        {
                            type: 'application/ld+json',
                            json: structuredDataObject,
                            body: true,
                        },
                    ],
                };
            }
        }
    },
};
</script>
