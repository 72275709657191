<template>
    <div class="x-swipe-container" :class="classes">
        <div class="fixed swipable" :style="contentStyle" @click="onClickFixed">
            <slot></slot>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
    data() {
        return {
            panX: 0,
            isPanning: false,
            isActive: false,
            activeSide: null,
            hasSwiped: false,
            hasPanned: false,
            threshold: 30,
        };
    },

    computed: {
        ...mapGetters({
            overlayIsActive: 'ui/overlayIsActive',
            menuIsActive: 'ui/menuIsActive',
            menuPan: 'ui/menuPan',
            menuIsPanning: 'ui/menuIsPanning',
        }),

        classes() {
            return {
                'is-panning': this.isPanning,
                'is-active': this.isActive,
            };
        },

        paneStyle() {
            if (this.isPanning) {
                const x = this.isActive ? `calc(100% + ${this.panX}px)` : `${this.panX}px`;
                return {
                    transform: `translate3D(${x}, 0px, 0px)  scale(1, 1) rotate3d(0, 0, 0, 0deg)`,
                };
            } else if (this.isActive) {
                return {
                    transform: `translate3D(calc(100%), 0px, 0px)  scale(1, 1) rotate3d(0, 0, 0, 0deg)`,
                };
            }
        },

        contentStyle() {
            if (this.isPanning) {
                const x = this.isActive ? `calc(100% - 4rem + ${this.panX}px)` : `${this.panX}px`;
                return {
                    transform: `translate3D(${x}, 0px, 0px)  scale(1, 1) rotate3d(0, 0, 0, 0deg)`,
                };
            } else if (this.isActive) {
                return {
                    transform: `translate3D(100%, 0px, 0px)  scale(1, 1) rotate3d(0, 0, 0, 0deg)`,
                };
            } else {
            }
        },
    },

    mounted() {
        if (false && this.$device.isMobile() && !this.$device.isIOS()) {
            // DO NOT MESS WITH THIS (start)
            const applyHammer = async () => {
                const Hammer = null; // (await import('hammerjs')).default;

                this.hammer = new Hammer(this.$el, {
                    inputClass: Hammer.TouchInput,
                });

                this.hammer.on('panstart', this.onPanStart);
                this.hammer.on('panleft', this.onPanLeft);
                this.hammer.on('panright', this.onPanRight);
                this.hammer.on('panend', this.onPanEnd);

                this.hammer.on('swiperight', this.onSwipeRight);
                this.hammer.on('swipeleft', this.onSwipeLeft);
            };

            this.hammerPromise = applyHammer();

            // DO NOT MESS WITH THIS (end)
        }
    },

    destroyed() {
        if (this.hammerPromise) {
            this.hammerPromise.then(() => {
                if (this.hammer) {
                    this.hammer.destroy();
                }
            });
        }
    },

    methods: {
        onClickFixed() {
            if (this.isActive && !this.isPanning) {
                this.closeSide();
            }
        },

        startPanning() {
            if (!this.isPanning) {
                const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

                if (width < 768) {
                    this.isPanning = true;
                    this.hasSwiped = false;
                    this.hasPanned = false;

                    this.$emit('pan:start');
                }
            }
        },

        stopPanning() {
            if (this.isPanning) {
                this.panX = 0;
                this.isPanning = false;
                this.$emit('pan:end');
            }
        },

        openSide(side) {
            this.stopPanning();

            this.isActive = true;
            this.activeSide = side;
            this.$emit('side:open', side);
        },

        closeSide() {
            this.stopPanning();
            this.isActive = false;

            this.$emit('side:close');
        },

        onPanStart(ev) {
            if (this.$device.isIOS() && (ev.center.x < 50 || ev.center.x > this.$device.getWidth() - 50)) {
                this.hammer.stop();
                return false;
            }
            if (Math.abs(ev.deltaX) > this.threshold && Math.abs(ev.deltaY) < Math.abs(ev.deltaX)) {
                this.deltaX = ev.deltaX;
                this.startPanning();
            }
        },

        onPanEnd(ev) {
            if (this.isPanning) {
                if (this.hasSwiped) {
                    this.stopPanning();
                } else {
                    const threshold = document.body.clientWidth / 4.5;

                    if (ev.deltaX > threshold) {
                        this.hasPanned = true;
                        this.openSide('left');
                    } else if (ev.deltaX < -threshold) {
                        this.hasPanned = true;
                        this.closeSide();
                    } else {
                        this.stopPanning();
                    }
                }

                this.isPanning = false;
            }
        },

        onPanLeft(ev) {
            if (this.isPanning) {
                if (!this.hasSwiped) {
                    this.panX = ev.deltaX - this.deltaX;
                }
            } else {
                this.onPanStart(ev);
            }
        },

        onPanRight(ev) {
            if (this.isPanning) {
                if (!this.hasSwiped) {
                    this.panX = ev.deltaX - this.deltaX;
                }
            } else {
                this.onPanStart(ev);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.x-swipe-container {
    overflow-x: hidden;
    position: relative;

    .content {
        position: relative;
        z-index: 0;
        overflow: hidden;
    }

    .fixed {
        position: fixed;
        top: 0;
        left: -100%;
        bottom: 0;
        width: 100%;
        z-index: 10000;

        transform: translate3D(0px, 0px, 0px) scale(1, 1) rotate3d(0, 0, 0, 0deg);
    }

    &.is-active {
        .fixed {
            bottom: 0;
        }

        .fixed.swipable::after {
            opacity: 1;
            box-shadow: inset 0 1px 20px rgba(0, 0, 0, 0.35);
        }
    }

    &.is-panning {
        .fixed {
            height: 100vh;
        }

        .fixed::after {
            opacity: 1;
            box-shadow: inset 0 1px 20px rgba(0, 0, 0, 0.35);
        }
    }

    .swipable {
        transition: transform 0.25s ease-out;
        will-change: transform;
    }

    &.is-panning {
        .swipable {
            transition: none;
        }
    }
}
</style>
